import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  tAuthors,
  tAuthor,
  tBooks,
  tCategories,
  tCategory,
  tBook,
} from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import useData from "../../hooks/useData/useData";

import { NumberInput } from "../../components/Form/Input/Input";
import Select, { SimpleSelect } from "../../components/Form/Select/Select";
import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import Icon from "../../icons/Icon";

import { getSkip, roundDP } from "../../utils/func";
import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

const Books = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { authors: authorsData, categories: categoriesData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchCategories, fetchAuthors } = useData();

  const [search, setSearch] = useState("");

  const [minPrice, setMinPrice] = useState<string | number>("");
  const [maxPrice, setMaxPrice] = useState<string | number>("");

  const [authors, setAuthors] = useState<tAuthors>([]);
  const [categories, setCategories] = useState<tCategories>([]);

  const [topOptions] = useState(["All", "Yes", "No"]);
  const [top, setTop] = useState("All");

  const [featuredOptions] = useState(["All", "Yes", "No"]);
  const [featured, setFeatured] = useState("All");

  const fetchInterval = useRef<number | null>(null);
  const isFetching = useRef(false);

  const [page, setPage] = useState(1);
  const [division] = useState(15);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(1);

  const [books, setBooks] = useState<tBooks>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  const [showFilters, setShowFilters] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState<tBook | null>(null);
  const [deleting, setDeleting] = useState<tBook | null>(null);

  const [errorM, setErrorM] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const deleteBook = (book: tBook) => {
    setDeleteConfirm(null);

    setDeleting(book);

    api_client({
      method: "DELETE",
      url: `/books/${book._id}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        setSuccess({
          title: "Deleted!",
          message: "Book deleted successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  setSuccess(null);
                }}
              >
                Close
              </button>
            </div>
          ),
          closeHandler: () => {
            setSuccess(null);
          },
        });
        setReload((rl) => !rl);
      })
      .catch((err) => {
        setErrorM(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        setDeleting(null);
      });
  };

  const deleteHandler = (book: tBook) => {
    if (deleteConfirm || deleting) return;

    setDeleteConfirm(book);
  };

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setError(false);
    setLoading(true);

    fetchInterval.current = window.setInterval(() => {
      if (isFetching.current) return;

      isFetching.current = true;

      let authorsQuery = authors.map((author) => author._id).join(",");
      let categoriesQuery = categories
        .map((category) => category._id)
        .join(",");

      api_client({
        url: `/books?page=${page}&division=${division}${
          search ? `&search=${search}` : ""
        }${top !== "All" ? `&top=${top === "Yes" ? "true" : "false"}` : ""}${
          featured !== "All"
            ? `&featured=${featured === "Yes" ? "true" : "false"}`
            : ""
        }${authorsQuery ? `&authors=${authorsQuery}` : ""}${
          categoriesQuery ? `&categories=${categoriesQuery}` : ""
        }${minPrice ? `&min_price=${minPrice}` : ""}${
          maxPrice ? `&max_price=${maxPrice}` : ""
        }`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const bks: tBooks = res.data.data.books;

          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          if (!bks.length && page > 1) return setPage(page - 1);

          setBooks(bks);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
          isFetching.current = false;
        });
    }, 3000);
  }, [
    search,
    minPrice,
    maxPrice,
    categories,
    authors,
    top,
    featured,
    page,
    division,
    accessToken,
    reload,
  ]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    fetchAuthors();
    fetchCategories();
  }, [fetchCategories, fetchAuthors]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  const filterExists =
    search ||
    minPrice !== "" ||
    maxPrice !== "" ||
    categories.length ||
    authors.length ||
    top !== "All" ||
    featured !== "All";

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}

      <SuccessModal success={success} />
      <ErrorModal errorMsg={errorM} closeHandler={() => setErrorM("")} />

      {deleteConfirm ? (
        <ConfirmationModal
          confirm={{
            title: "Delete Book?",
            message: `Are you sure you want to delete book ${deleteConfirm.Name}. This action cannot be reversed`,
            closeHandler: () => setDeleteConfirm(null),
            confirmHandler: () => deleteBook(deleteConfirm),
          }}
        />
      ) : null}

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Books</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Books</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/add-book" className="btn">
            <Icon name="add" />
            Add Book
          </Link>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body">
          <div className="fgroup">
            <label className="label">Amount</label>
            <div className="range-group">
              <NumberInput
                label="$"
                type="amount"
                placeholder="10"
                bind={[minPrice, setMinPrice]}
              />
              <div className="range-group__divider"></div>
              <NumberInput
                label="$"
                type="amount"
                placeholder="10"
                bind={[maxPrice, setMaxPrice]}
              />
            </div>
          </div>
          <div className="fgroup">
            <label htmlFor="categories-field" className="label">
              Categories
            </label>
            <Select<tCategory>
              type="multiple"
              placeholder="Select categories"
              useSearch={true}
              data={categoriesData}
              bind={[categories, setCategories]}
              transform={{ uniqueKey: "_id", displayKey: "Name" }}
            />
          </div>
          <div className="fgroup">
            <label htmlFor="authors-field" className="label">
              Authors
            </label>
            <Select<tAuthor>
              type="multiple"
              placeholder="Select authors"
              useSearch={true}
              data={authorsData}
              bind={[authors, setAuthors]}
              transform={{ uniqueKey: "_id", displayKey: "PenName" }}
            />
          </div>
          <div className="fgroup">
            <label className="label">Top Book?</label>
            <SimpleSelect
              type="single"
              placeholder="Select..."
              useSearch={false}
              bind={[top, setTop]}
              data={topOptions}
            />
          </div>
          <div className="fgroup">
            <label className="label">Featured Book?</label>
            <SimpleSelect
              type="single"
              placeholder="Select..."
              useSearch={false}
              bind={[featured, setFeatured]}
              data={featuredOptions}
            />
          </div>
        </div>
        <div className="tfilter__footer" onClick={() => toggleFilters()}>
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by anything...."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={() => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
            <div className="table-controls__filters"></div>
          </div>
          <div className="table-controls__right">
            <button className="button" onClick={() => toggleFilters()}>
              <Icon name="filter" />
              Filters
            </button>
          </div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th>
                      <div className="th">Categories</div>
                    </th>
                    <th>
                      <div className="th">Authors</div>
                    </th>
                    <th>
                      <div className="th">Price</div>
                    </th>
                    <th>
                      <div className="th">Top</div>
                    </th>
                    <th>
                      <div className="th">Featured</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {error ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="text-center">
                          Error fetching books.{" "}
                          <span
                            className="text-link"
                            onClick={() => setReload((rl) => !rl)}
                          >
                            Try again
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && !books.length ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="text-center">
                          There are no books{" "}
                          {filterExists
                            ? "in current filter"
                            : "currently on the platform"}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && books.length
                    ? books.map((book) => (
                        <tr key={book._id}>
                          <td>#{book._id.slice(0, 6)}</td>
                          <td>
                            <div className="tinfo">
                              <img
                                src={book.ThumbnailPath}
                                alt=""
                                className="tinfo__img"
                              />
                              <div className="tinfo__main">
                                <h3 className="tinfo__heading">{book.Name}</h3>
                                <p className="tinfo__desc">
                                  {book.Description}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="tags">
                              {book.Categories.map((cat) => (
                                <div className="tag tag--1" key={cat._id}>
                                  <span>{cat.Name}</span>
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div>
                              {book.Authors.map((athr) => (
                                <p key={athr._id}>{athr.PenName}</p>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="titem">
                              ${roundDP(book.Price, 2)}
                            </div>
                          </td>
                          <td>
                            <div className="titem">
                              {book.Top ? "YES" : "NO"}
                            </div>
                          </td>
                          <td>
                            <div className="titem">
                              {book.Featured ? "YES" : "NO"}
                            </div>
                          </td>
                          <td>
                            <div className="tactions">
                              <button
                                className="taction"
                                onClick={() =>
                                  navigate(`/book/${book._id}/edit`)
                                }
                              >
                                <IonIcon icon={createOutline} /> Edit
                              </button>
                              <button
                                className="taction"
                                onClick={() => deleteHandler(book)}
                              >
                                {deleting?._id === book._id ? (
                                  <>
                                    <span className="fas fa-spinner fa-spin"></span>{" "}
                                    Deleting
                                  </>
                                ) : (
                                  <>
                                    <IonIcon icon={trashOutline} />
                                    Delete
                                  </>
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {!loading && !error && numRecords
              ? `Showing ${start} - ${end} of ${numRecords} results`
              : null}
          </div>
          <div className="pagination">
            <button onClick={() => (page > 1 ? setPage(page - 1) : null)}>
              <IonIcon icon={chevronBackOutline} />
            </button>
            {new Array(pagination).fill(null).map((_, i) => (
              <button className={cls(page - 1 === i && "active")} key={i}>
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => (page < pagination ? setPage(page + 1) : null)}
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Books);
