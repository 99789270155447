import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tAuthor, tAuthors } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import Icon from "../../icons/Icon";

import { getSkip } from "../../utils/func";
import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

const Authors = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [search, setSearch] = useState("");

  const fetchInterval = useRef<number | null>(null);
  const isFetching = useRef(false);

  const [page, setPage] = useState(1);
  const [division] = useState(15);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(1);

  const [authors, setAuthors] = useState<tAuthors>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  const [deleteConfirm, setDeleteConfirm] = useState<tAuthor | null>(null);
  const [deleting, setDeleting] = useState<tAuthor | null>(null);

  const [errorM, setErrorM] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const deleteAuthor = (author: tAuthor) => {
    setDeleteConfirm(null);

    setDeleting(author);

    api_client({
      method: "DELETE",
      url: `/authors/${author._id}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        setSuccess({
          title: "Deleted!",
          message: "Author deleted successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  setSuccess(null);
                }}
              >
                Close
              </button>
            </div>
          ),
          closeHandler: () => {
            setSuccess(null);
          },
        });
        setReload((rl) => !rl);
      })
      .catch((err) => {
        setErrorM(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        setDeleting(null);
      });
  };

  const deleteHandler = (author: tAuthor) => {
    if (deleteConfirm || deleting) return;

    setDeleteConfirm(author);
  };

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setError(false);
    setLoading(true);

    fetchInterval.current = window.setInterval(() => {
      if (isFetching.current) return;

      isFetching.current = true;

      api_client({
        url: `/authors?page=${page}&division=${division}${
          search ? `&search=${search}` : ""
        }`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const authrs: tAuthors = res.data.data.authors;

          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          if (!authrs.length && page > 1) return setPage(page - 1);

          setAuthors(authrs);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
          isFetching.current = false;
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  const filterExists = !!search;

  return (
    <DashboardLayout>
      <SuccessModal success={success} />
      <ErrorModal errorMsg={errorM} closeHandler={() => setErrorM("")} />

      {deleteConfirm ? (
        <ConfirmationModal
          confirm={{
            title: "Delete Author?",
            message: `Are you sure you want to delete author ${deleteConfirm.PenName}. This action cannot be reversed`,
            closeHandler: () => setDeleteConfirm(null),
            confirmHandler: () => deleteAuthor(deleteConfirm),
          }}
        />
      ) : null}

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Authors</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Authors</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/add-author" className="btn">
            <Icon name="add" />
            Add Author
          </Link>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by anything...."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={(e) => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
          </div>
          <div className="table-controls__right"></div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th>
                      <div className="th">Books</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {error ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">
                          Error fetching authors.{" "}
                          <span
                            className="text-link"
                            onClick={() => setReload((rl) => !rl)}
                          >
                            Try again
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && !authors.length ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">
                          There are no authors{" "}
                          {filterExists
                            ? "in current filter"
                            : "currently on the platform"}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && authors.length
                    ? authors.map((author) => (
                        <tr key={author._id}>
                          <td>#{author._id.slice(0, 6)}</td>
                          <td>
                            <div className="tinfo">
                              <div className="tinfo__main">
                                <h3 className="tinfo__heading">
                                  {author.PenName}
                                </h3>
                                <p className="tinfo__desc">
                                  {author.Description}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="titem">{author.NumBooks} books</div>
                          </td>
                          <td>
                            <div className="tactions f-justify-start">
                              <button
                                className="taction"
                                onClick={() =>
                                  navigate(`/author/${author._id}/edit`)
                                }
                              >
                                <IonIcon icon={createOutline} />
                                Edit
                              </button>
                              {!author.NumBooks ? (
                                <button
                                  className="taction"
                                  onClick={() => deleteHandler(author)}
                                >
                                  {deleting?._id === author._id ? (
                                    <>
                                      <span className="fas fa-spinner fa-spin"></span>{" "}
                                      Deleting
                                    </>
                                  ) : (
                                    <>
                                      <IonIcon icon={trashOutline} />
                                      Delete
                                    </>
                                  )}
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {!loading && !error && numRecords
              ? `Showing ${start} - ${end} of ${numRecords} results`
              : null}
          </div>
          <div className="pagination">
            <button onClick={() => (page > 1 ? setPage(page - 1) : null)}>
              <IonIcon icon={chevronBackOutline} />
            </button>
            {new Array(pagination).fill(null).map((_, i) => (
              <button className={cls(page - 1 === i && "active")} key={i}>
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => (page < pagination ? setPage(page + 1) : null)}
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Authors);
