import { useEffect } from "react";
import { useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import Preloader from "../../components/Preloader/Preloader";

const Logout = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  useEffect(() => {
    api_client({
      url: "/admins/logout",
      method: "DELETE",
      headers: { Authorization: `Bearer ${accessToken}` },
    }).finally(() => {
      localStorage.removeItem("appState");
      window.location.assign("/login");
    });
  }, [accessToken]);

  return <Preloader />;
};

export default Logout;
