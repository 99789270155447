import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tAuthors, tCategories, tOverview, tSales } from "./types/app.types";

type CacheType = {
  recentSales: tSales | null;
  overview: tOverview | null;
  categories: tCategories;
  authors: tAuthors;
};

const CacheInitialState: CacheType = {
  recentSales: null,
  overview: null,
  categories: [],
  authors: [],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateRecentSales(state, { payload }: PayloadAction<tSales>) {
      state.recentSales = payload;

      saveState<CacheType>("cache", current(state));
    },
    updateOverview(state, { payload }: PayloadAction<tOverview>) {
      state.overview = payload;

      saveState<CacheType>("cache", current(state));
    },
    updateAuthors(state, { payload }: PayloadAction<tAuthors>) {
      state.authors = payload;

      saveState<CacheType>("cache", current(state));
    },
    updateCategories(state, { payload }: PayloadAction<tCategories>) {
      state.categories = payload;

      saveState<CacheType>("cache", current(state));
    },
  },
});

export const {
  updateRecentSales,
  updateOverview,
  updateAuthors,
  updateCategories,
} = cacheSlice.actions;

export default cacheSlice.reducer;
