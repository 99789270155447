import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import Icon from "../../icons/Icon";

type tParentSidebarLink = {
  icon: string;
  text: string;
  badge?: {
    type: "red" | "green";
    text: string;
  };
};

type tSingleLink = tParentSidebarLink & { hasSubMenu: false; link: string };

type tSubMenuLink = tParentSidebarLink & {
  hasSubMenu: true;
  links: { text: string; link: string }[];
};

type tSidebarLink = tSingleLink | tSubMenuLink;

const SidebarLink = (props: tSidebarLink) => {
  const currentPath = useLocation().pathname;

  const isActive = props.hasSubMenu
    ? props.links.some((link) => link.link === currentPath)
    : props.link === currentPath;

  const open = isActive && props.hasSubMenu;

  const [trigger, setTrigger] = useState(false);

  const subMenuLinkRef = useRef<HTMLAnchorElement | null>(null);
  const subMenuRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (!trigger || !subMenuLinkRef.current || !subMenuRef.current) return;

    const subMenuLink = subMenuLinkRef.current;
    const subMenu = subMenuRef.current;

    if (open) {
      subMenu.style.maxHeight = subMenu.scrollHeight + "px";
    }

    subMenuLink.addEventListener("click", function () {
      if (subMenu.style.maxHeight && subMenu.style.maxHeight !== "0px") {
        subMenu.style.maxHeight = "0px";
        this.classList.remove("open");
      } else {
        subMenu.style.maxHeight = subMenu.scrollHeight + "px";
        this.classList.add("open");
      }
    });
  }, [trigger, open]);

  return (
    <li className="sidebar__nav-item">
      <Link
        to={props.hasSubMenu ? "" : props.link}
        className={cls("sidebar__link", isActive && "active", open && "open")}
        ref={subMenuLinkRef}
      >
        <Icon name={props.icon} className="sidebar__link-img" />
        <span className="sidebar__link-text"> {props.text} </span>
        {props.badge ? (
          <span
            className={`sidebar__link-badge sidebar__link-badge--${props.badge.type}`}
          >
            {props.badge.text}
          </span>
        ) : null}
        {props.hasSubMenu ? (
          <IonIcon
            icon={chevronForwardOutline}
            className="sidebar__link-icon"
          />
        ) : null}
      </Link>
      {props.hasSubMenu ? (
        <ul
          className="sidebar__submenu"
          ref={(el) => {
            subMenuRef.current = el;
            setTrigger(true);
          }}
        >
          <li></li>
          {props.links.map((link, i) => (
            <li className="sidebar__submenu-item" key={i}>
              <Link
                to={link.link}
                className={cls(
                  "sidebar__sub-link",
                  currentPath === link.link && "active"
                )}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default SidebarLink;
