import { useDispatch } from "react-redux";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { login } from "../../store/userReducer";

import Auth from "../../layouts/Auth/Auth";

import useData from "../../hooks/useData/useData";
import useAlert from "../../hooks/useAlert/useAlert";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchOverview } = useData();

  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password)
      return setMessage("warning", "Fill in all fields");

    const submitBtn = submitBtnRef.current;
    const currentHTML = submitBtn.innerHTML;

    submitBtn.setAttribute("disabled", "disabled");
    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Signing In`;

    let accessToken = "";

    api_client({
      url: "/admins/login",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { Username: username, Password: password },
    })
      .then((res) => {
        accessToken = res.data.data.accessToken;

        return fetchOverview({ accessToken, raiseError: true });
      })
      .then((res) => {
        dispatch(login({ accessToken }));

        navigate("/");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (!submitBtn) return;
        submitBtn.innerHTML = currentHTML;
        submitBtn.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    clearMessage();
  }, [username, password, clearMessage]);

  return (
    <Auth>
      <div className="auth__header">
        <h1 className="auth__heading">
          Welcome back! Please <br />
          Sign in to continue.
        </h1>
      </div>
      <form className="auth__form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username-field" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter your username"
            id="username-field"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password-field" className="form-label">
            Password
          </label>
          <div className="form-input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-input"
              placeholder="Enter your password"
              id="password-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="button" onClick={() => setShowPassword((sp) => !sp)}>
              <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
            </button>
          </div>
        </div>
        <div className="auth__form-footer">
          <button
            className="btn btn--md btn--dark"
            ref={submitBtnRef}
            disabled={!username || !password}
          >
            Sign In
            <IonIcon icon={arrowForwardOutline} />
          </button>
          {message}
        </div>
      </form>
    </Auth>
  );
};

export default Login;
