import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tSales } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import SaleRow from "../../components/SaleRow/SaleRow";
import { NumberInput } from "../../components/Form/Input/Input";
import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import Icon from "../../icons/Icon";

import { getSkip } from "../../utils/func";

const Sales = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [minPrice, setMinPrice] = useState<string | number>("");
  const [maxPrice, setMaxPrice] = useState<string | number>("");

  const fetchInterval = useRef<number | null>(null);
  const isFetching = useRef(false);

  const [page, setPage] = useState(1);
  const [division] = useState(15);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(1);

  const [sales, setSales] = useState<tSales>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setError(false);
    setLoading(true);

    fetchInterval.current = window.setInterval(() => {
      if (isFetching.current) return;

      isFetching.current = true;

      api_client({
        url: `/sales?page=${page}&division=${division}${
          minPrice ? `&min_price=${minPrice}` : ""
        }${maxPrice ? `&max_price=${maxPrice}` : ""}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const sls: tSales = res.data.data.sales;

          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          if (!sls.length && page > 1) return setPage(page - 1);

          setSales(sls);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
          isFetching.current = false;
        });
    }, 3000);
  }, [minPrice, maxPrice, page, division, accessToken, reload]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  const filterExists = minPrice !== "" || maxPrice !== "";

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Sales</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Sales</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <button className="button" onClick={() => toggleFilters()}>
            <Icon name="filter" />
            Filter
          </button>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body">
          <div className="fgroup">
            <label className="label">Amount</label>
            <div className="range-group">
              <NumberInput
                label="$"
                type="amount"
                placeholder="10"
                bind={[minPrice, setMinPrice]}
              />
              <div className="range-group__divider"></div>
              <NumberInput
                label="$"
                type="amount"
                placeholder="10"
                bind={[maxPrice, setMaxPrice]}
              />
            </div>
          </div>
        </div>
        <div className="tfilter__footer" onClick={() => toggleFilters()}>
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">User</div>
                    </th>
                    <th>
                      <div className="th">Books</div>
                    </th>
                    <th>
                      <div className="th">Amount</div>
                    </th>
                    <th>
                      <div className="th">Charge</div>
                    </th>
                    <th>
                      <div className="th">Profit</div>
                    </th>
                    <th>
                      <div className="th">Date</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={9}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {error ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="text-center">
                          Error fetching sales.{" "}
                          <span
                            className="text-link"
                            onClick={() => setReload((rl) => !rl)}
                          >
                            Try again
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && !sales.length ? (
                    <tr>
                      <td colSpan={9}>
                        <div className="text-center">
                          There are no sales{" "}
                          {filterExists
                            ? "in current filter"
                            : "currently on the platform"}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && sales.length
                    ? sales.map((sale) => (
                        <Fragment key={sale._id}>
                          <SaleRow sale={sale} />
                        </Fragment>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {!loading && !error && numRecords
              ? `Showing ${start} - ${end} of ${numRecords} results`
              : null}
          </div>
          <div className="pagination">
            <button onClick={() => (page > 1 ? setPage(page - 1) : null)}>
              <IonIcon icon={chevronBackOutline} />
            </button>
            {new Array(pagination).fill(null).map((_, i) => (
              <button className={cls(page - 1 === i && "active")} key={i}>
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => (page < pagination ? setPage(page + 1) : null)}
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Sales);
