import { Link, useNavigate, useParams } from "react-router-dom";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";
import { useEffect, useState } from "react";
import { tSale } from "../../store/types/app.types";
import Preloader from "../../components/Preloader/Preloader";
import { useSelector } from "react-redux";
import { tRootState } from "../../store";

import api_client from "../../api/client";
import { getDateTime, roundDP } from "../../utils/func";

const Sale = () => {
  const { id: saleId } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [sale, setSale] = useState<tSale | null>(null);

  useEffect(() => {
    if (!saleId) return;

    api_client({
      url: `/sales/${saleId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        setSale(res.data.data);
      })
      .catch((err) => {
        navigate("/not-found");
      });
  }, [saleId, accessToken, navigate]);

  if (!sale) return <Preloader />;

  const [date, time] = getDateTime(sale.DatePaid);

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">
            Sale #{sale._id.slice(0, 15).toUpperCase()}
          </h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="">Sales</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>{sale._id.slice(0, 15).toUpperCase()}</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right"></div>
      </div>
      <div className="order-details">
        <div className="order-details__summary">
          <div>
            {date} at {time}
          </div>
          <div>{sale.CartBooks.length} Items</div>
          <div>Total ${roundDP(sale.Amount, 2)}</div>
          <div>
            <span className="badge badge--rounded badge--success">Paid</span>
          </div>
        </div>

        <div className="order-details__content">
          <div className="order-details__main">
            <div className="table-responsive">
              <table className="table table--no-border">
                <thead>
                  <tr>
                    <th>
                      <div className="th">Product</div>
                    </th>
                    <th>
                      <div className="th th--right">Price</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sale.CartBooks.map((cartBook) => (
                    <tr key={cartBook._id}>
                      <td>
                        <div className="tproduct tproduct--sm">
                          <img
                            src={cartBook.Book.ThumbnailPath}
                            alt=""
                            className="tproduct__img"
                          />
                          <div className="tproduct__main">
                            <p className="tproduct__name">
                              {cartBook.Book.Name}
                            </p>
                            <p className="tproduct__category">Book</p>
                            <div className="tproduct__unit-price">
                              Current Price: ${roundDP(cartBook.Book.Price, 2)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="titem align-right">
                          ${roundDP(cartBook.Amount, 2)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="order-summary">
              <div className="order-summary__main">
                <div>
                  Total
                  <span>${roundDP(sale.Amount, 2)}</span>
                </div>
                <div>
                  Charge
                  <span>${roundDP(sale.Charge, 2)}</span>
                </div>
                <div>
                  Profit
                  <span>${roundDP(sale.AmountGained, 2)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="order-details__right">
            <div className="order-details__block">
              <h3 className="order-details__block-heading">Customer Details</h3>
              <div className="order-details__user">
                <img
                  src={sale.User.ProfilePicturePath}
                  alt=""
                  className="order-details__user-img"
                />
                <div className="order-details__user-main">
                  <h3 className="order-details__user-name">
                    {sale.User.FullName}
                  </h3>
                  <p className="order-details__user-email">
                    {sale.User.EmailAddress}
                  </p>
                  <p className="order-details__user-phone">
                    {sale.User.Username}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Sale;
