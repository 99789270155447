import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

type UserType = {
  accessToken: string;
};

const UserInitialState: UserType = {
  accessToken: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: getState<UserType>("user", UserInitialState),
  reducers: {
    login(
      state,
      {
        payload,
      }: PayloadAction<{
        accessToken: string;
      }>
    ) {
      state.accessToken = payload.accessToken;

      saveState<UserType>("user", current(state));
    },
    logout(state) {
      state.accessToken = "";

      saveState<UserType>("user", current(state));
    },
  },
});

export const { login } = userSlice.actions;

export default userSlice.reducer;
