import { useState } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";

const Header = ({ openSidebar }: { openSidebar: () => void }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="header">
      <div className="header__left">
        <button
          className="header__sidebar-toggle"
          onClick={() => openSidebar()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
            ></path>
          </svg>
        </button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 48 26"
          className="header__logo"
        >
          <rect
            width="10.16"
            height="19.93"
            fill="currentColor"
            rx="5.08"
            transform="rotate(29.49 -5.18 20.77) skewX(.85)"
          ></rect>
          <rect
            width="10.16"
            height="25.62"
            fill="currentColor"
            rx="5.08"
            transform="matrix(.87 .492 -.48 .878 27.17 0)"
          ></rect>
          <rect
            width="10.16"
            height="10.25"
            fill="currentColor"
            opacity=".5"
            rx="5.08"
            transform="rotate(29.49 -8.24 75.34) skewX(.85)"
          ></rect>
        </svg>
        <div className="hsearch">
          <IonIcon icon={searchOutline} />
          <p>Search your page...</p>
          <button>Ctrl + K</button>
        </div>
      </div>
      <div className="header__right">
        <div className="header__user">
          <button
            className="header__user-btn"
            onClick={() => setShowMenu((sm) => !sm)}
          >
            <img
              src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-11.webp"
              alt=""
            />
          </button>
          <div className={cls("user-menu", showMenu && "open")}>
            <div className="user-menu__user">
              <img
                src="https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-11.webp"
                alt=""
                className="user-menu__user-img"
              />
              <div className="user-menu__user-main">
                <p className="user-menu__user-name">Omicab Admin</p>
                <p className="user-menu__user-email">admin@omicab.com</p>
              </div>
            </div>
            {/* <div className="user-menu__links">
              <Link to="/profile" className="user-menu__link">
                My Profile
              </Link>
              <Link to="/account-settings" className="user-menu__link">
                Account Settings
              </Link>
              <Link to="/activity-log" className="user-menu__link">
                Activity Log
              </Link>
            </div> */}
            <Link to="/logout" className="user-menu__sign-out">
              Sign Out
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
