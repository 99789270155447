import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  updateAuthors,
  updateCategories,
  updateOverview,
  updateRecentSales,
} from "../../store/cacheReducer";

const useData = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { overview, recentSales } = useSelector(
    (state: tRootState) => state.cache
  );

  const overviewExists = overview !== null;
  const recentSalesExists = recentSales !== null;

  const fetchOverview = useCallback(
    (options: { raiseError?: boolean; accessToken?: string } = {}) => {
      if (!accessToken && !options.accessToken) return;

      return new Promise((resolve, reject) => {
        api_client({
          url: "/overview",
          headers: {
            Authorization: `Bearer ${options.accessToken || accessToken}`,
          },
        })
          .then((res) => {
            dispatch(updateOverview(res.data.data));

            resolve("Overview fetched successfully");
          })
          .catch((err) => {
            if (options.raiseError) {
              reject(err);
            } else {
              if (!overviewExists) return reject(err);
              resolve("Error fetching overview");
            }
          });
      });
    },
    [accessToken, overviewExists, dispatch]
  );

  const fetchRecentSales = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: "/sales?page=1&division=10",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateRecentSales(res.data.data.sales));

          resolve("Overview fetched successfully");
        })
        .catch((err) => {
          if (!recentSalesExists) return reject(err);

          resolve("Overview fetched successfully");
        });
    });
  }, [accessToken, recentSalesExists, dispatch]);

  const fetchCategories = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: `/categories?no_pagination=true`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateCategories(res.data.data.categories));

          resolve("Categories fetched successfully");
        })
        .catch(() => {
          resolve("Error fetching categories");
        });
    });
  }, [accessToken, dispatch]);

  const fetchAuthors = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        url: `/authors?no_pagination=true`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateAuthors(res.data.data.authors));

          resolve("Authors fetched successfully");
        })
        .catch(() => {
          resolve("Error fetching authors");
        });
    });
  }, [accessToken, dispatch]);

  return {
    fetchOverview,
    fetchCategories,
    fetchAuthors,
    fetchRecentSales,
  };
};

export default useData;
