import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";
import htmr from "htmr";

import { IonIcon } from "@ionic/react";
import { eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCategory } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import { TextInput } from "../../components/Form/Input/Input";
import Textarea from "../../components/Form/Textarea/Textarea";
import Checkbox from "../../components/Form/Checkbox/Checkbox";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";

import Preloader from "../../components/Preloader/Preloader";
import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";

const AddEditCategory = () => {
  const { id: categoryId } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [category, setCategory] = useState<tCategory | null>(null);
  const [updates, setUpdates] = useState(true);

  const [reload, setReload] = useState(false);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [top, setTop] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || !icon) return setError("Fill in all fields");

    if (!updates) return setError("No changes made");

    const submitBtn = submitBtnRef.current;
    const currentHTML = submitBtn.innerHTML;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtn.setAttribute("disabled", "disabled");

    api_client({
      url: category ? `/categories/${category._id}` : "/categories",
      method: category ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: { Name: name, Icon: icon, Top: top },
    })
      .then((res) => {
        if (category) {
          setReload((rl) => !rl);
          return setSuccess({
            title: "Updated!",
            message: "Category updated successfully",
            controls: (
              <div className="success-modal__btns">
                <button
                  className="button"
                  onClick={() => {
                    setSuccess(null);
                  }}
                >
                  Close
                </button>
              </div>
            ),
            closeHandler: () => {
              setSuccess(null);
            },
          });
        }

        setSuccess({
          title: "Added!",
          message: "Category added successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  navigate(`/category/${res.data.data.category}/edit`);
                }}
              >
                Edit
              </button>
              <button
                className="btn"
                onClick={() => {
                  navigate("/categories");
                }}
              >
                Finish
              </button>
            </div>
          ),
        });
      })
      .catch((err) => {
        setError(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        if (!submitBtn) return;
        submitBtn.innerHTML = currentHTML;
        submitBtn.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    if (!category || !name || !icon) return;

    if (category.Name !== name) return setUpdates(true);

    if ((htmr(category.Icon) as string) !== icon) return setUpdates(true);

    if (category.Top !== top) return setUpdates(true);

    return setUpdates(false);
  }, [category, name, icon, top]);

  useEffect(() => {
    if (!categoryId) return;

    api_client({
      url: `/categories/${categoryId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        const cat: tCategory = res.data.data;

        setName(cat.Name);
        setIcon(htmr(cat.Icon) as string);
        setTop(cat.Top);

        setCategory(cat);
      })
      .catch((err) => {
        navigate("/not-found");
      });
  }, [categoryId, reload, accessToken, navigate]);

  if (categoryId && !category) return <Preloader />;

  return (
    <DashboardLayout>
      <SuccessModal success={success} />
      <ErrorModal errorMsg={error} closeHandler={() => setError("")} />

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">
            {category ? "Edit" : "Add"} Category
          </h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/categories">Categories</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>{category ? "Edit" : "Add"}</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/categories" className="btn">
            <IonIcon icon={eyeOutline} />
            View Categories
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-tab">
          <ul className="form-tab__main">
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="details"
              >
                <span> Details </span>
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="stepped-form-container">
          <ScrollContent className="stepped-form" id="details">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Details</h3>
              <p className="stepped-form__subheading">
                Edit your category details and necessary information from here
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="name-field" className="label">
                      Name
                    </label>
                    <TextInput
                      type="text"
                      placeholder="Category name"
                      bind={[name, setName]}
                    />
                  </div>
                </div>
                <div className="grid-full">
                  <label className="label">
                    <Checkbox
                      checked={top}
                      changeHandler={(e) => setTop(e.target.checked)}
                    />
                    Add to Top Categories
                  </label>
                </div>
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="icon-field" className="label">
                      Icon
                    </label>
                    <Textarea
                      rows={12}
                      placeholder="SVG Icon Code"
                      bind={[icon, setIcon]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollContent>
        </div>
        <footer className="btn-footer">
          <button
            className="btn"
            disabled={!name || !icon || !updates}
            ref={submitBtnRef}
          >
            {category ? "Edit" : "Add"} Category
          </button>
        </footer>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(AddEditCategory);
