import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollContent = ({
  children,
  className,
  id,
}: {
  children: ReactNode;
  className: string;
  id: string;
}) => {
  const location = useLocation();

  const hash = `#${id}`;
  const isActive = location.hash === hash;

  const element = useRef<HTMLDivElement | null>(null);

  const isVisible = (element: HTMLDivElement | null) => {
    if (!element) return;

    const rect = element.getBoundingClientRect();
    return rect.top - 150 < window.innerHeight && rect.bottom > 0;
  };

  useEffect(() => {
    if (!isActive) return;

    element.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }, [isActive]);

  // work on observer
  // currently intercepting with navigation scroll
  // scroll alternative can't get algorithm for isVisible
  // dont navigate toggle active state for link
  useEffect(() => {
    if (!element.current) return;

    document.addEventListener("scroll", () => {
      if (isVisible(element.current)) {
        // console.log(`${id} intersecting`);
        // toggle link active state if intersecting
      }
    });
  }, []);

  return (
    <div id={id} className={className} ref={element}>
      {children}
    </div>
  );
};

export default ScrollContent;
