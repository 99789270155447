import { ReactNode, useEffect } from "react";

export type tSuccess = {
  title: string;
  message: string;
  controls?: ReactNode;
  closeHandler?: () => void;
} | null;

const SuccessModal = ({ success }: { success: tSuccess }) => {
  useEffect(() => {
    if (success) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [success]);

  if (!success) return null;

  return (
    <>
      <div className="success-modal open">
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </div>
        <h3>{success.title}</h3>
        <p>{success.message}</p>
        {success.controls}
      </div>
      <div
        className="modal-overlay"
        onClick={() => (success.closeHandler ? success.closeHandler() : null)}
      ></div>
    </>
  );
};

export default SuccessModal;
