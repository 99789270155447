import { useEffect } from "react";

const ErrorModal = ({
  errorMsg,
  closeHandler,
}: {
  errorMsg: string;
  closeHandler: () => void;
}) => {
  useEffect(() => {
    if (errorMsg) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [errorMsg]);

  if (!errorMsg) return null;

  return (
    <>
      <div className="success-modal open">
        <div className="icon warning">
          <span className="body"></span>
          <span className="dot"></span>
        </div>
        <p className="text-center">{errorMsg}</p>
        <div className="success-modal__btns">
          <button
            className="button"
            onClick={() => {
              closeHandler();
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-overlay" onClick={() => closeHandler()}></div>
    </>
  );
};

export default ErrorModal;
