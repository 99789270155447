import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { eyeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tAuthor } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import Textarea from "../../components/Form/Textarea/Textarea";
import { TextInput } from "../../components/Form/Input/Input";

import Preloader from "../../components/Preloader/Preloader";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";

import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";

const AddEditAuthor = () => {
  const { id: authorId } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [author, setAuthor] = useState<tAuthor | null>(null);
  const [updates, setUpdates] = useState(true);

  const [reload, setReload] = useState(false);

  const [penName, setPenName] = useState("");
  const [description, setDescription] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!penName || !description) return setError("Fill in all fields");

    if (!updates) return setError("No changes made");

    const submitBtn = submitBtnRef.current;
    const currentHTML = submitBtn.innerHTML;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtn.setAttribute("disabled", "disabled");

    api_client({
      url: author ? `/authors/${author._id}` : "/authors",
      method: author ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: { PenName: penName, Description: description },
    })
      .then((res) => {
        if (author) {
          setReload((rl) => !rl);
          return setSuccess({
            title: "Updated!",
            message: "Author updated successfully",
            controls: (
              <div className="success-modal__btns">
                <button
                  className="button"
                  onClick={() => {
                    setSuccess(null);
                  }}
                >
                  Close
                </button>
              </div>
            ),
            closeHandler: () => {
              setSuccess(null);
            },
          });
        }

        setSuccess({
          title: "Added!",
          message: "Author added successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  navigate(`/author/${res.data.data.author}/edit`);
                }}
              >
                Edit
              </button>
              <button
                className="btn"
                onClick={() => {
                  navigate("/authors");
                }}
              >
                Finish
              </button>
            </div>
          ),
        });
      })
      .catch((err) => {
        setError(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        if (!submitBtn) return;
        submitBtn.innerHTML = currentHTML;
        submitBtn.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    if (!author || !penName || !description) return;

    if (author.PenName !== penName) return setUpdates(true);

    if (author.Description !== description) return setUpdates(true);

    return setUpdates(false);
  }, [author, penName, description]);

  useEffect(() => {
    if (!authorId) return;

    api_client({
      url: `/authors/${authorId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        const authr: tAuthor = res.data.data;

        setPenName(authr.PenName);
        setDescription(authr.Description);

        setAuthor(authr);
      })
      .catch((err) => {
        navigate("/not-found");
      });
  }, [authorId, accessToken, reload, navigate]);

  if (authorId && !author) return <Preloader />;

  return (
    <DashboardLayout>
      <SuccessModal success={success} />
      <ErrorModal errorMsg={error} closeHandler={() => setError("")} />

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">
            {author ? "Edit" : "Add"} Author
          </h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/authors">Authors</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>{author ? "Edit" : "Add"}</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/authors" className="btn">
            <IonIcon icon={eyeOutline} />
            View Authors
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-tab">
          <ul className="form-tab__main">
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="details"
              >
                <span> Details </span>
              </ScrollLink>
            </li>
          </ul>
        </div>

        <div className="stepped-form-container">
          <ScrollContent className="stepped-form" id="details">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Details</h3>
              <p className="stepped-form__subheading">
                Edit your author details and necessary information from here
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="name-field" className="label">
                      Pen Name
                    </label>
                    <TextInput
                      type="text"
                      placeholder="Author pen name"
                      bind={[penName, setPenName]}
                    />
                  </div>
                </div>
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="description-field" className="label">
                      Description
                    </label>
                    <Textarea
                      rows={7}
                      placeholder="About the author"
                      bind={[description, setDescription]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollContent>
        </div>

        <footer className="btn-footer">
          <button
            className="btn"
            ref={submitBtnRef}
            disabled={!description || !penName || !updates}
          >
            {author ? "Edit" : "Add"} Author
          </button>
        </footer>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(AddEditAuthor);
