import { useEffect, useRef, useState } from "react";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronUpOutline } from "ionicons/icons";

import Checkbox from "../Checkbox/Checkbox";

interface iSingleScalarSelect {
  placeholder?: string;
  useSearch?: boolean;
  bind: [string, (newValue: string) => void];
  data: string[];
}

interface iMultipleScalarSelect {
  placeholder?: string;
  useSearch?: boolean;
  bind: [string[], (newValue: string[]) => void];
  data: string[];
}

interface iSingleObjectSelect<T extends object> {
  placeholder?: string;
  useSearch?: boolean;
  bind: [T, (newValue: T) => void];
  data: T[];
  transform: { uniqueKey: keyof T; displayKey: keyof T };
}

interface iMultipleObjectSelect<T extends object> {
  placeholder?: string;
  useSearch?: boolean;
  bind: [T[], (newValue: T[]) => void];
  data: T[];
  transform: { uniqueKey: keyof T; displayKey: keyof T };
}

const SingleScalarSelect = ({
  placeholder,
  useSearch,
  bind: [value, setValue],
  data,
}: iSingleScalarSelect) => {
  const selectElement = useRef<HTMLDivElement | null>(null);

  const [openSelect, setOpenSelect] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    selectElement.current?.addEventListener("focusout", function (event) {
      // Check if the newly focused element is outside the popup and closes it
      if (
        !this.contains(event.relatedTarget as Node | null) &&
        event.relatedTarget !== selectElement.current
      )
        setOpenSelect(false);
    });
  }, []);

  return (
    <div className="select" ref={selectElement}>
      <div className={cls("select-input", openSelect && "open")}>
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          value={value}
          onChange={(e) => null}
          onClick={() => setOpenSelect((os) => !os)}
          readOnly
        />
        <span className="select-input__icon-box">
          <IonIcon icon={chevronUpOutline} className="select-input__icon" />
        </span>
      </div>
      <div className="select-box">
        {useSearch ? (
          <div className="select-box__input-block">
            <input
              type="text"
              className="input input--sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        ) : null}
        <ul className="select-box__list" tabIndex={0}>
          {data
            .filter((dt) => new RegExp(search, "i").test(dt))
            .map((dt, i) => {
              const isChecked = dt === value;

              return (
                <li
                  className={cls(
                    "select-box__list-item",
                    isChecked && "active"
                  )}
                  key={i}
                  onClick={() => {
                    setValue(dt);
                    setOpenSelect(false);
                  }}
                >
                  <p>{dt}</p>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const MultipleScalarSelect = ({
  placeholder,
  useSearch,
  bind: [value, setValue],
  data,
}: iMultipleScalarSelect) => {
  const selectElement = useRef<HTMLDivElement | null>(null);

  const [openSelect, setOpenSelect] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    selectElement.current?.addEventListener("focusout", function (event) {
      // Check if the newly focused element is outside the popup and closes it
      if (
        !this.contains(event.relatedTarget as Node | null) &&
        event.relatedTarget !== selectElement.current
      )
        setOpenSelect(false);
    });
  }, []);

  return (
    <div className="select" ref={selectElement}>
      <div className={cls("select-input", openSelect && "open")}>
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          value={value.join(", ")}
          onChange={(e) => null}
          onClick={() => setOpenSelect((os) => !os)}
          readOnly
        />
        <span className="select-input__icon-box">
          <IonIcon icon={chevronUpOutline} className="select-input__icon" />
        </span>
      </div>
      <div className="select-box">
        {useSearch ? (
          <div className="select-box__input-block">
            <input
              type="text"
              className="input input--sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        ) : null}
        <ul className="select-box__list" tabIndex={0}>
          {data
            .filter((dt) => new RegExp(search, "i").test(dt))
            .map((dt, i) => {
              const isChecked = value.includes(dt);

              return (
                <li key={i}>
                  <label
                    className={cls(
                      "select-box__list-item",
                      isChecked && "active"
                    )}
                  >
                    <Checkbox
                      sm
                      checked={isChecked}
                      changeHandler={(e) => {
                        setValue(
                          e.target.checked
                            ? [...value, dt]
                            : value.filter((val) => val !== dt)
                        );
                      }}
                    />
                    <p>{dt}</p>
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const SingleObjectSelect = <T extends object>({
  placeholder,
  useSearch,
  bind: [value, setValue],
  data,
  transform: { uniqueKey, displayKey },
}: iSingleObjectSelect<T>) => {
  const selectElement = useRef<HTMLDivElement | null>(null);

  const [openSelect, setOpenSelect] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    selectElement.current?.addEventListener("focusout", function (event) {
      // Check if the newly focused element is outside the popup and closes it
      if (
        !this.contains(event.relatedTarget as Node | null) &&
        event.relatedTarget !== selectElement.current
      )
        setOpenSelect(false);
    });
  }, []);

  return (
    <div className="select" ref={selectElement}>
      <div className={cls("select-input", openSelect && "open")}>
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          value={value[displayKey] as string}
          onChange={(e) => null}
          onClick={() => setOpenSelect((os) => !os)}
          readOnly
        />
        <span className="select-input__icon-box">
          <IonIcon icon={chevronUpOutline} className="select-input__icon" />
        </span>
      </div>
      <div className="select-box">
        {useSearch ? (
          <div className="select-box__input-block">
            <input
              type="text"
              className="input input--sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        ) : null}
        <ul className="select-box__list" tabIndex={0}>
          {data
            .filter((dt) =>
              new RegExp(search, "i").test(dt[displayKey] as string)
            )
            .map((dt, i) => {
              const isChecked = dt[uniqueKey] === value[uniqueKey];

              return (
                <li
                  className={cls(
                    "select-box__list-item",
                    isChecked && "active"
                  )}
                  key={i}
                  onClick={() => {
                    setValue(dt);
                    setOpenSelect(false);
                  }}
                >
                  <p>{dt[displayKey] as string}</p>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const MultipleObjectSelect = <T extends object>({
  placeholder,
  useSearch,
  bind: [value, setValue],
  data,
  transform: { uniqueKey, displayKey },
}: iMultipleObjectSelect<T>) => {
  const selectElement = useRef<HTMLDivElement | null>(null);

  const [openSelect, setOpenSelect] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    selectElement.current?.addEventListener("focusout", function (event) {
      // Check if the newly focused element is outside the popup and closes it
      if (
        !this.contains(event.relatedTarget as Node | null) &&
        event.relatedTarget !== selectElement.current
      )
        setOpenSelect(false);
    });
  }, []);

  return (
    <div className="select" ref={selectElement}>
      <div className={cls("select-input", openSelect && "open")}>
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          value={value.map((dt) => dt[displayKey] as string).join(", ")}
          onChange={(e) => null}
          onClick={() => setOpenSelect((os) => !os)}
          readOnly
        />
        <span className="select-input__icon-box">
          <IonIcon icon={chevronUpOutline} className="select-input__icon" />
        </span>
      </div>
      <div className="select-box">
        {useSearch ? (
          <div className="select-box__input-block">
            <input
              type="text"
              className="input input--sm"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        ) : null}
        <ul className="select-box__list" tabIndex={0}>
          {data
            .filter((dt) =>
              new RegExp(search, "i").test(dt[displayKey] as string)
            )
            .map((dt, i) => {
              const isChecked = value.some(
                (val) => val[uniqueKey] === dt[uniqueKey]
              );

              return (
                <li key={i}>
                  <label
                    className={cls(
                      "select-box__list-item",
                      isChecked && "active"
                    )}
                  >
                    <Checkbox
                      sm
                      checked={isChecked}
                      changeHandler={(e) => {
                        setValue(
                          e.target.checked
                            ? [...value, dt]
                            : value.filter(
                                (val) => val[uniqueKey] !== dt[uniqueKey]
                              )
                        );
                      }}
                    />
                    <p>{dt[displayKey] as string}</p>
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

type tSimpleSelect =
  | ({
      type: "single";
    } & iSingleScalarSelect)
  | ({
      type: "multiple";
    } & iMultipleScalarSelect);

export const SimpleSelect = (props: tSimpleSelect) => {
  return props.type === "single" ? (
    <SingleScalarSelect {...props} />
  ) : (
    <MultipleScalarSelect {...props} />
  );
};

const Select = <T extends object>(
  props:
    | ({
        type: "single";
      } & iSingleObjectSelect<T>)
    | ({
        type: "multiple";
      } & iMultipleObjectSelect<T>)
) => {
  return props.type === "single" ? (
    <SingleObjectSelect {...props} />
  ) : (
    <MultipleObjectSelect {...props} />
  );
};

export default Select;
