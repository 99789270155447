import AddIcon from "./icons/AddIcon";
import BrowserIcon from "./icons/BrowserIcon";
import CheckIcon from "./icons/CheckIcon";
import FilterIcon from "./icons/FilterIcon";
import FolderIcon from "./icons/FolderIcon";
import HourGlassIcon from "./icons/HourGlass";
import PackageIcon from "./icons/PackageIcon";
import TagIcon from "./icons/TagIcon";
import UserIcon from "./icons/UserIcon";

const Icon = ({
  name,
  className = "",
}: {
  name: string;
  className?: string;
}) => {
  if (name === "folder") return <FolderIcon className={className} />;
  if (name === "user") return <UserIcon className={className} />;
  if (name === "package") return <PackageIcon className={className} />;
  if (name === "hour-glass") return <HourGlassIcon className={className} />;
  if (name === "tag") return <TagIcon className={className} />;
  if (name === "browser") return <BrowserIcon className={className} />;
  if (name === "check") return <CheckIcon className={className} />;
  if (name === "add") return <AddIcon className={className} />;
  if (name === "filter") return <FilterIcon className={className} />;

  return null;
};

export default Icon;
