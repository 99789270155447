import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import {
  cubeOutline,
  folderOutline,
  logoUsd,
  walletOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import SaleRow from "../../components/SaleRow/SaleRow";
import Preloader from "../../components/Preloader/Preloader";
import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import { roundDP } from "../../utils/func";

const Dashboard = () => {
  const { overview, recentSales } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchOverview, fetchRecentSales } = useData();

  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  useEffect(() => {
    fetchOverview();
  }, [fetchOverview]);

  useEffect(() => {
    fetchRecentSales().catch((err) => {
      setError(true);
    });
  }, [reload, fetchRecentSales]);

  if (!overview) return <Preloader />;

  return (
    <DashboardLayout>
      <div className="p-small">
        <div className="info-grids mb-small">
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--1">
                <IonIcon icon={walletOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Users</p>
                <p className="info-grid__info-value">{overview.totalUsers}</p>
              </div>
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--2">
                <IonIcon icon={cubeOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Categories</p>
                <p className="info-grid__info-value">
                  {overview.totalCategories}
                </p>
              </div>
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--3">
                <IonIcon icon={logoUsd} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Authors</p>
                <p className="info-grid__info-value">{overview.totalAuthors}</p>
              </div>
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--4">
                <IonIcon icon={folderOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Books</p>
                <p className="info-grid__info-value">{overview.totalBooks}</p>
              </div>
            </div>
          </div>
          <div className="info-grid">
            <div className="info-grid__main">
              <div className="info-grid__icon-block info-grid__icon-block--4">
                <IonIcon icon={folderOutline} />
              </div>
              <div className="info-grid__info">
                <p className="info-grid__info-key">Total Earnings</p>
                <p className="info-grid__info-value">
                  ${roundDP(overview.totalEarnings.amount, 2)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="table-block">
          <div className="table-block__header">
            <div className="table-header">
              <h3 className="table-header__heading">Recent Sales</h3>
              <p className="table-header__sub-heading">
                Last 10 sales made on the website platform
              </p>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="th">ID</div>
                  </th>
                  <th>
                    <div className="th">User</div>
                  </th>
                  <th>
                    <div className="th">Books</div>
                  </th>
                  <th>
                    <div className="th">Amount</div>
                  </th>
                  <th>
                    <div className="th">Charge</div>
                  </th>
                  <th>
                    <div className="th">Profit</div>
                  </th>
                  <th>
                    <div className="th">Date</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!recentSales && !error ? (
                  <tr>
                    <td colSpan={9}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error && !recentSales ? (
                  <tr>
                    <td colSpan={9}>
                      <div className="text-center">
                        Error fetching recent sales.{" "}
                        <span
                          className="text-link"
                          onClick={() => setReload((rl) => !rl)}
                        >
                          Try again
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : null}
                {recentSales && !recentSales.length ? (
                  <tr>
                    <td colSpan={9}>
                      <div className="text-center">
                        There are no sales currently on the platform
                      </div>
                    </td>
                  </tr>
                ) : null}

                {recentSales && recentSales.length
                  ? recentSales.map((sale) => (
                      <Fragment key={sale._id}>
                        <SaleRow sale={sale} />
                      </Fragment>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Dashboard);
