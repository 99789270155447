import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  cloudUploadOutline,
  documentOutline,
  eyeOutline,
  trashBinOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  tAuthors,
  tAuthor,
  tCategories,
  tCategory,
  tFullBook,
} from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import useData from "../../hooks/useData/useData";

import Icon from "../../icons/Icon";

import Select from "../../components/Form/Select/Select";
import Preloader from "../../components/Preloader/Preloader";
import Checkbox from "../../components/Form/Checkbox/Checkbox";
import Textarea from "../../components/Form/Textarea/Textarea";
import ScrollLink from "../../components/Scrollable/ScrollLink";
import ScrollContent from "../../components/Scrollable/ScrollContent";
import { NumberInput, TextInput } from "../../components/Form/Input/Input";

import { formatBytes, getInputDate } from "../../utils/func";

import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

const AddEditBook = () => {
  const { id: bookId } = useParams();

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );
  const { authors: authorsData, categories: categoriesData } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchCategories, fetchAuthors } = useData();

  const [book, setBook] = useState<tFullBook | null>(null);
  const [updates, setUpdates] = useState(true);

  const [reload, setReload] = useState(false);

  const [name, setName] = useState("");
  const [price, setPrice] = useState<string | number>("");
  const [description, setDescription] = useState("");

  const [authors, setAuthors] = useState<tAuthors>([]);
  const [categories, setCategories] = useState<tCategories>([]);

  const [amazonLink, setAmazonLink] = useState("");

  const [featured, setFeatured] = useState(false);
  const [top, setTop] = useState(false);

  const [datePublished, setDatePublished] = useState("");
  const [language, setLanguage] = useState("");
  const [pages, setPages] = useState<string | number>("");

  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const thumbnailInputRef = useRef<HTMLInputElement>(null);

  const [pdf, setPDF] = useState<File | null>(null);
  const pdfInputRef = useRef<HTMLInputElement>(null);

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !name ||
      !price ||
      !categories.length ||
      !authors.length ||
      !description ||
      !datePublished ||
      !language ||
      !pages ||
      (!book && !thumbnail) ||
      (!book && !pdf)
    )
      return setError("Fill in all fields");

    if (!updates) return setError("No changes made");

    const submitBtn = submitBtnRef.current;
    const currentHTML = submitBtn.innerHTML;

    submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    submitBtn.setAttribute("disabled", "disabled");

    const bookData: any = {
      Name: name,
      Description: description,
      Price: price,
      AmazonLink: amazonLink,
      DatePublished: datePublished,
      Language: language,
      Pages: pages,
      Top: top,
      Featured: featured,
      Categories: categories.map((category) => category._id),
      Authors: authors.map((author) => author._id),
    };

    const formData = new FormData();

    formData.append("bookData", JSON.stringify(bookData));
    if (thumbnail) formData.append("thumbnail", thumbnail);
    if (pdf) formData.append("pdf", pdf);

    api_client({
      url: book ? `/books/${book._id}` : "/books",
      method: book ? "PATCH" : "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: formData,
    })
      .then((res) => {
        if (book) {
          setReload((rl) => !rl);
          return setSuccess({
            title: "Updated!",
            message: "Book updated successfully",
            controls: (
              <div className="success-modal__btns">
                <button
                  className="button"
                  onClick={() => {
                    setSuccess(null);
                  }}
                >
                  Close
                </button>
              </div>
            ),
            closeHandler: () => {
              setSuccess(null);
            },
          });
        }

        setSuccess({
          title: "Added!",
          message: "Book added successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  navigate(`/book/${res.data.data.book}/edit`);
                }}
              >
                Edit
              </button>
              <button
                className="btn"
                onClick={() => {
                  navigate("/books");
                }}
              >
                Finish
              </button>
            </div>
          ),
        });
      })
      .catch((err) => {
        setError(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        if (!submitBtn) return;
        submitBtn.innerHTML = currentHTML;
        submitBtn.removeAttribute("disabled");
      });
  };

  useEffect(() => {
    if (
      !book ||
      !name ||
      !price ||
      !categories.length ||
      !authors.length ||
      !description ||
      !datePublished ||
      !language ||
      !pages
    )
      return;

    if (book.Name !== name) return setUpdates(true);
    if (book.Price !== price) return setUpdates(true);
    if (book.Description !== description) return setUpdates(true);
    if (
      book.Categories.length !== categories.length ||
      book.Categories.some(
        (cat) => categories.findIndex((ct) => ct._id === cat._id) === -1
      )
    )
      return setUpdates(true);
    if (
      book.Authors.length !== authors.length ||
      book.Authors.some(
        (aut) => authors.findIndex((au) => au._id === aut._id) === -1
      )
    )
      return setUpdates(true);
    if (book.Featured !== featured) return setUpdates(true);
    if (book.Top !== top) return setUpdates(true);
    if (book.AmazonLink !== amazonLink) return setUpdates(true);
    if (
      new Date(book.DatePublished).getTime() !==
      new Date(datePublished).getTime()
    )
      return setUpdates(true);
    if (book.Language !== language) return setUpdates(true);
    if (book.Pages !== pages) return setUpdates(true);

    if (thumbnail) return setUpdates(true);
    if (pdf) return setUpdates(true);

    setUpdates(false);
  }, [
    book,
    name,
    price,
    description,
    categories,
    authors,
    featured,
    top,
    amazonLink,
    datePublished,
    language,
    pages,
    pdf,
    thumbnail,
  ]);

  useEffect(() => {
    fetchAuthors();
    fetchCategories();
  }, [fetchCategories, fetchAuthors]);

  useEffect(() => {
    if (!bookId) return;

    api_client({
      url: `/books/${bookId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        const bk: tFullBook = res.data.data;

        setName(bk.Name);
        setPrice(bk.Price);
        setDescription(bk.Description);
        setAmazonLink(bk.AmazonLink || "");
        setFeatured(bk.Featured);
        setTop(bk.Top);
        setLanguage(bk.Language);
        setPages(bk.Pages);
        setDatePublished(getInputDate(bk.DatePublished));
        setAuthors(bk.Authors);
        setCategories(bk.Categories);

        setBook(bk);
      })
      .catch((err) => {
        navigate("/not-found");
      });
  }, [bookId, reload, accessToken, navigate]);

  if (bookId && !book) return <Preloader />;

  return (
    <DashboardLayout>
      <SuccessModal success={success} />
      <ErrorModal errorMsg={error} closeHandler={() => setError("")} />

      <input
        type="file"
        style={{ display: "none" }}
        ref={thumbnailInputRef}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length) {
            setThumbnail(files[0]);

            e.target.value = "";
          }
        }}
        accept=".jpg,.png,.jpeg"
      />
      <input
        type="file"
        style={{ display: "none" }}
        ref={pdfInputRef}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length) {
            setPDF(files[0]);

            e.target.value = "";
          }
        }}
        accept=".pdf"
      />
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">{book ? "Edit" : "Add"} Book</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="/books">Books</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>{book ? "Edit" : "Add"}</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/books" className="btn">
            <IonIcon icon={eyeOutline} />
            View Books
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-tab">
          <ul className="form-tab__main">
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="details"
              >
                <span> Details </span>
              </ScrollLink>
            </li>
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="more-details"
              >
                <span> More Details </span>
              </ScrollLink>
            </li>
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="thumbnail"
              >
                <span> Thumbnail </span>
              </ScrollLink>
            </li>
            <li className="form-tab__item">
              <ScrollLink
                className={({ active }) =>
                  cls("form-tab__link", active && "active")
                }
                targetId="pdf"
              >
                <span> PDF </span>
              </ScrollLink>
            </li>
          </ul>
        </div>
        <div className="stepped-form-container">
          <ScrollContent className="stepped-form" id="details">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Details</h3>
              <p className="stepped-form__subheading">
                Edit your book description and necessary information from here
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="fgroup">
                  <label htmlFor="name-field" className="label">
                    Name
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Book name"
                    bind={[name, setName]}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="price-field" className="label">
                    Price
                  </label>
                  <NumberInput
                    label="$"
                    type="amount"
                    placeholder="10"
                    bind={[price, setPrice]}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="categories-field" className="label">
                    Categories
                  </label>
                  <Select<tCategory>
                    type="multiple"
                    placeholder="Select categories"
                    useSearch={true}
                    data={categoriesData}
                    bind={[categories, setCategories]}
                    transform={{ uniqueKey: "_id", displayKey: "Name" }}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="authors-field" className="label">
                    Authors
                  </label>
                  <Select<tAuthor>
                    type="multiple"
                    placeholder="Select authors"
                    useSearch={true}
                    data={authorsData}
                    bind={[authors, setAuthors]}
                    transform={{ uniqueKey: "_id", displayKey: "PenName" }}
                  />
                </div>
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="description-field" className="label">
                      Description
                    </label>
                    <Textarea
                      rows={7}
                      placeholder="Book description"
                      bind={[description, setDescription]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollContent>
          <ScrollContent className="stepped-form" id="more-details">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">More Details</h3>
              <p className="stepped-form__subheading">
                Enter more details about the book
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="stepped-form__grid">
                <div className="grid-full">
                  <label className="label">
                    <Checkbox
                      checked={featured}
                      changeHandler={(e) => setFeatured(e.target.checked)}
                    />
                    Add to Featured Books
                  </label>
                </div>
                <div className="grid-full">
                  <label className="label">
                    <Checkbox
                      checked={top}
                      changeHandler={(e) => setTop(e.target.checked)}
                    />
                    Add to Top Books
                  </label>
                </div>
                <div className="grid-full">
                  <div className="fgroup">
                    <label htmlFor="pdate-field-name-field" className="label">
                      Amazon Link
                    </label>
                    <TextInput
                      type="url"
                      placeholder="Enter amazon link"
                      bind={[amazonLink, setAmazonLink]}
                    />
                  </div>
                </div>
                <div className="fgroup">
                  <label htmlFor="date-published-field" className="label">
                    Date Published
                  </label>
                  <input
                    type="date"
                    className="input"
                    value={datePublished}
                    onChange={(e) => setDatePublished(e.target.value)}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="language-field" className="label">
                    Language
                  </label>
                  <TextInput
                    type="text"
                    placeholder="e.g English"
                    bind={[language, setLanguage]}
                  />
                </div>
                <div className="fgroup">
                  <label htmlFor="pages-field" className="label">
                    Pages
                  </label>
                  <NumberInput
                    type="number"
                    placeholder="Enter number of pages"
                    bind={[pages, setPages]}
                  />
                </div>
              </div>
            </div>
          </ScrollContent>
          <ScrollContent className="stepped-form" id="thumbnail">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Upload book thumbnail</h3>
              <p className="stepped-form__subheading">
                Upload your book thumbnail here
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="file">
                <div className={cls("file-input", thumbnail && "active")}>
                  <input type="file" />
                  <div
                    className="file-input__left"
                    onClick={() => thumbnailInputRef.current?.click()}
                  >
                    <Icon name="upload" />
                    Drop or select file
                  </div>
                  {thumbnail ? (
                    <div className="file-input__right">
                      <button
                        type="button"
                        className="button"
                        onClick={() => setThumbnail(null)}
                      >
                        <IonIcon icon={trashBinOutline} />
                        Clear 1 file
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => thumbnailInputRef.current?.click()}
                      >
                        <IonIcon icon={cloudUploadOutline} /> Change file
                      </button>
                    </div>
                  ) : null}
                </div>
                {thumbnail ? (
                  <div className="file-previews">
                    <div className="file-preview file-preview--media">
                      <div
                        className="file-preview__header"
                        onClick={() =>
                          window.open(URL.createObjectURL(thumbnail), "_blank")
                        }
                      >
                        <button
                          type="button"
                          className="file-preview__remove"
                          onClick={(e) => {
                            setThumbnail(null);
                            e.stopPropagation();
                          }}
                        >
                          <IonIcon icon={trashBinOutline} />
                        </button>
                        <img src={URL.createObjectURL(thumbnail)} alt="" />
                      </div>
                      <div className="file-preview__body">
                        <p className="file-preview__name">
                          <strong>IMAGE:</strong> {thumbnail.name}
                        </p>
                        <span className="file-preview__size">
                          {formatBytes(thumbnail.size)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : book ? (
                  <div className="file-previews">
                    <div className="file-preview file-preview--media">
                      <div
                        className="file-preview__header"
                        onClick={() =>
                          window.open(book.ThumbnailPath, "_blank")
                        }
                      >
                        <img src={book.ThumbnailPath} alt="" />
                      </div>
                      <div className="file-preview__body">
                        <p className="file-preview__name">
                          <strong>IMAGE:</strong> {book.Slug}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </ScrollContent>
          <ScrollContent className="stepped-form" id="pdf">
            <div className="stepped-form__header">
              <h3 className="stepped-form__heading">Upload book PDF</h3>
              <p className="stepped-form__subheading">
                Upload your book PDF here
              </p>
            </div>
            <div className="stepped-form__main">
              <div className="file">
                <div className={cls("file-input", pdf && "active")}>
                  <input type="file" />
                  <div
                    className="file-input__left"
                    onClick={() => pdfInputRef.current?.click()}
                  >
                    <Icon name="upload" />
                    Drop or select file
                  </div>
                  {pdf ? (
                    <div className="file-input__right">
                      <button
                        type="button"
                        className="button"
                        onClick={() => setPDF(null)}
                      >
                        <IonIcon icon={trashBinOutline} />
                        Clear 1 file
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => pdfInputRef.current?.click()}
                      >
                        <IonIcon icon={cloudUploadOutline} /> Change file
                      </button>
                    </div>
                  ) : null}
                </div>
                {pdf ? (
                  <div className="file-previews">
                    <div className="file-preview file-preview--media">
                      <div
                        className="file-preview__header"
                        onClick={() =>
                          window.open(URL.createObjectURL(pdf), "_blank")
                        }
                      >
                        <button
                          type="button"
                          className="file-preview__remove"
                          onClick={(e) => {
                            setPDF(null);
                            e.stopPropagation();
                          }}
                        >
                          <IonIcon icon={trashBinOutline} />
                        </button>
                        <IonIcon
                          icon={documentOutline}
                          className="file-preview__icon"
                        />
                      </div>
                      <div className="file-preview__body">
                        <p className="file-preview__name">
                          <strong>IMAGE:</strong> {pdf.name}
                        </p>
                        <span className="file-preview__size">
                          {formatBytes(pdf.size)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : book ? (
                  <div className="file-previews">
                    <div className="file-preview file-preview--media">
                      <div
                        className="file-preview__header"
                        onClick={() => window.open(book.PDFPath, "_blank")}
                      >
                        <IonIcon
                          icon={documentOutline}
                          className="file-preview__icon"
                        />
                      </div>
                      <div className="file-preview__body">
                        <p className="file-preview__name">
                          <strong>IMAGE:</strong> {book.Slug}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </ScrollContent>
        </div>
        <footer className="btn-footer">
          <button
            className="btn"
            ref={submitBtnRef}
            disabled={
              !name ||
              !price ||
              !categories.length ||
              !authors.length ||
              !description ||
              !datePublished ||
              !language ||
              !pages ||
              (!book && !thumbnail) ||
              (!book && !pdf) ||
              !updates
            }
          >
            {book ? "Edit" : "Add"} Book
          </button>
        </footer>
      </form>
    </DashboardLayout>
  );
};

export default withAuth(AddEditBook);
