import { ChangeEvent } from "react";
import cls from "classnames";

import Icon from "../../../icons/Icon";

type tCheckbox = {
  sm?: boolean;
  checked: boolean;
  changeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ sm, checked, changeHandler }: tCheckbox) => {
  return (
    <span className={cls("checkbox", sm && "checkbox--sm")}>
      <input type="checkbox" checked={checked} onChange={changeHandler} />
      <Icon name="check" />
    </span>
  );
};

export default Checkbox;
