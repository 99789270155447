import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  searchOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tUsers } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import { getSkip } from "../../utils/func";

const Users = () => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [search, setSearch] = useState("");

  const fetchInterval = useRef<number | null>(null);
  const isFetching = useRef(false);

  const [page, setPage] = useState(1);
  const [division] = useState(15);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(1);

  const [users, setUsers] = useState<tUsers>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setError(false);
    setLoading(true);

    fetchInterval.current = window.setInterval(() => {
      if (isFetching.current) return;

      isFetching.current = true;

      api_client({
        url: `/users?page=${page}&division=${division}${
          search ? `&search=${search}` : ""
        }`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const usrs: tUsers = res.data.data.users;

          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          if (!usrs.length && page > 1) return setPage(page - 1);

          setUsers(usrs);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
          isFetching.current = false;
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  const filterExists = !!search;

  return (
    <DashboardLayout>
      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Users</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Users</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right"></div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by anything...."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={(e) => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
          </div>
          <div className="table-controls__right"></div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th>
                      <div className="th">Username</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={3}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {error ? (
                    <tr>
                      <td colSpan={3}>
                        <div className="text-center">
                          Error fetching users.{" "}
                          <span
                            className="text-link"
                            onClick={() => setReload((rl) => !rl)}
                          >
                            Try again
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && !users.length ? (
                    <tr>
                      <td colSpan={3}>
                        <div className="text-center">
                          There are no users{" "}
                          {filterExists
                            ? "in current filter"
                            : "currently on the platform"}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && users.length
                    ? users.map((user) => (
                        <tr key={user._id}>
                          <td>#{user._id.slice(0, 6)}</td>
                          <td>
                            <div className="tuser tuser--sm">
                              <img
                                src={user.ProfilePicturePath}
                                alt=""
                                className="tuser__img"
                              />
                              <div className="tuser__main">
                                <p className="tuser__name">{user.FullName}</p>
                                <p className="tuser__email">
                                  {user.EmailAddress}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="titem">{user.Username}</div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {!loading && !error && numRecords
              ? `Showing ${start} - ${end} of ${numRecords} results`
              : null}
          </div>
          <div className="pagination">
            <button onClick={() => (page > 1 ? setPage(page - 1) : null)}>
              <IonIcon icon={chevronBackOutline} />
            </button>
            {new Array(pagination).fill(null).map((_, i) => (
              <button className={cls(page - 1 === i && "active")} key={i}>
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => (page < pagination ? setPage(page + 1) : null)}
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Users);
