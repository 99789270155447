import { useEffect } from "react";

export type tConfirm = {
  title: string;
  message: string;
  closeHandler: () => void;
  confirmHandler: () => void;
} | null;

const ConfirmationModal = ({ confirm }: { confirm: tConfirm }) => {
  useEffect(() => {
    if (confirm) {
      document.querySelector("body")?.classList.add("modal-open");
    } else {
      document.querySelector("body")?.classList.remove("modal-open");
    }
  }, [confirm]);

  if (!confirm) return null;

  return (
    <>
      <div className="success-modal open">
        <h3>{confirm.title}</h3>
        <p className="text-center">{confirm.message}</p>
        <div className="success-modal__btns">
          <button
            className="button"
            onClick={() => {
              confirm.closeHandler();
            }}
          >
            Cancel
          </button>
          <button
            className="btn"
            onClick={() => {
              confirm.confirmHandler();
            }}
          >
            Continue
          </button>
        </div>
      </div>
      <div
        className="modal-overlay"
        onClick={() => confirm.closeHandler()}
      ></div>
    </>
  );
};

export default ConfirmationModal;
