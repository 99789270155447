type tTextArea = {
  placeholder?: string;
  rows?: number;
  bind: [string, (value: string) => void];
};

const Textarea = ({
  placeholder = "",
  rows = 2,
  bind: [value, setValue],
}: tTextArea) => {
  return (
    <textarea
      name=""
      rows={rows}
      className="input"
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    ></textarea>
  );
};

export default Textarea;
