import { useSelector } from "react-redux";

import { tRootState } from "../../store";

import { Navigate } from "react-router-dom";

const withAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const { accessToken } = useSelector((state: tRootState) => state.user);

    if (!accessToken) return <Navigate to="/login" />;

    return <Component {...props} />;
  };

export default withAuth;
