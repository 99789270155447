import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavigateCleanup from "./hoc/NavigateCleanup/NavigateCleanup";

import Dashboard from "./pages/Dashboard/Dashboard";

import Books from "./pages/Books/Books";
import AddEditBook from "./pages/AddEditBook/AddEditBook";

import Authors from "./pages/Authors/Authors";
import AddEditAuthor from "./pages/AddEditAuthor/AddEditAuthor";

import Categories from "./pages/Categories/Categories";
import AddEditCategory from "./pages/AddEditCategory/AddEditCategory";

import Users from "./pages/Users/Users";

import Sales from "./pages/Sales/Sales";
import Sale from "./pages/Sale/Sale";

import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";

function App() {
  return (
    <BrowserRouter>
      <NavigateCleanup />
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path="/categories" element={<Categories />} />
        <Route path="/add-category" element={<AddEditCategory />} />
        <Route path="/category/:id/edit" element={<AddEditCategory />} />

        <Route path="/authors" element={<Authors />} />
        <Route path="/add-author" element={<AddEditAuthor />} />
        <Route path="/author/:id/edit" element={<AddEditAuthor />} />

        <Route path="/books" element={<Books />} />
        <Route path="/add-book" element={<AddEditBook />} />
        <Route path="/book/:id/edit" element={<AddEditBook />} />

        <Route path="/users" element={<Users />} />

        <Route path="/sales" element={<Sales />} />
        <Route path="/sale/:id" element={<Sale />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
