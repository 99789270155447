import { MouseEvent, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ScrollLink = ({
  children,
  className,
  targetId,
}: {
  children: ReactNode;
  className: string | ((options: { active: boolean }) => string);
  targetId: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const targetHash = `#${targetId}`;
  const isActive = targetHash === location.hash;

  const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    navigate(`${location.pathname}${targetHash}`, { replace: true });

    const targetElement = document.querySelector(targetHash);

    targetElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  return (
    <a
      href={targetHash}
      onClick={clickHandler}
      className={
        typeof className === "string"
          ? className
          : className({ active: isActive })
      }
    >
      {children}
    </a>
  );
};

export default ScrollLink;
