import { IonIcon } from "@ionic/react";
import { tSale } from "../../store/types/app.types";
import { chevronUpOutline, eyeOutline } from "ionicons/icons";
import { getDateTime, roundDP } from "../../utils/func";
import { useState } from "react";
import cls from "classnames";
import { Link } from "react-router-dom";

const SaleRow = ({ sale }: { sale: tSale }) => {
  const [saleDate, saleTime] = getDateTime(sale.DatePaid);

  const [expand, setExpand] = useState(false);

  return (
    <>
      <tr>
        <td>
          <button
            className={cls("texpand", expand && "open")}
            onClick={() => setExpand((ex) => !ex)}
          >
            <IonIcon icon={chevronUpOutline} />
          </button>
        </td>
        <td>#{sale._id.slice(0, 6)}</td>
        <td>
          <div className="tuser tuser--sm">
            <img
              src={sale.User.ProfilePicturePath}
              alt=""
              className="tuser__img"
            />
            <div className="tuser__main">
              <p className="tuser__name">{sale.User.FullName}</p>
              <p className="tuser__email">{sale.User.EmailAddress}</p>
            </div>
          </div>
        </td>
        <td>
          <div className="tinfo">
            <div className="tinfo__main">
              <h3 className="tinfo__heading">{sale.CartBooks.length} books</h3>
              <p className="tinfo__desc">
                {sale.CartBooks.map((book) => book.Book.Name).join(", ")}
              </p>
            </div>
          </div>
        </td>
        <td>
          <p className="titem">${roundDP(sale.Amount, 2)}</p>
        </td>
        <td>
          <p className="titem">${roundDP(sale.Charge, 2)}</p>
        </td>
        <td>
          <p className="titem">${roundDP(sale.AmountGained, 2)}</p>
        </td>
        <td>
          <div className="tdate">
            <p className="tdate__date">{saleDate}</p>
            <p className="tdate__time">{saleTime}</p>
          </div>
        </td>
        <td>
          <div className="tactions">
            <Link to={`/sale/${sale._id}`} className="taction">
              <IonIcon icon={eyeOutline} /> View
            </Link>
          </div>
        </td>
      </tr>
      <tr className={cls("no-hover", !expand && "hidden")}>
        <td colSpan={9} className="py-0">
          <div>
            <table className="suborder-table">
              <tbody>
                {sale.CartBooks.map((cartBook) => (
                  <tr key={cartBook._id}>
                    <td>
                      <div className="tproduct tproduct--sm">
                        <img
                          src={cartBook.Book.ThumbnailPath}
                          alt=""
                          className="tproduct__img"
                        />
                        <div className="tproduct__main">
                          <p className="tproduct__name">{cartBook.Book.Name}</p>
                          <p className="tproduct__category">Book</p>
                          <div className="tproduct__unit-price">
                            Current Price: ${roundDP(cartBook.Book.Price, 2)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="titem align-right">
                        ${roundDP(cartBook.Amount, 2)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SaleRow;
