import { ReactNode, useState } from "react";

import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";

const Dashboard = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    document.querySelector("body")?.classList.add("modal-open");

    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    document.querySelector("body")?.classList.remove("modal-open");

    setSidebarOpen(false);
  };

  return (
    <>
      <Sidebar open={sidebarOpen} closeHandler={closeSidebar} />
      <div className="dashboard">
        <Header openSidebar={openSidebar} />
        <main className="main">{children}</main>
      </div>
    </>
  );
};

export default Dashboard;
