import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cls from "classnames";
import htmr from "htmr";

import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  closeOutline,
  createOutline,
  searchOutline,
  trashOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCategories, tCategory } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import DashboardLayout from "../../layouts/Dashboard/Dashboard";

import { SimpleSelect } from "../../components/Form/Select/Select";
import VerticalBarLoader from "../../components/VerticalBarLoader/VerticalBarLoader";

import Icon from "../../icons/Icon";

import { getSkip } from "../../utils/func";
import SuccessModal, {
  tSuccess,
} from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

const Categories = () => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [search, setSearch] = useState("");

  const [topOptions] = useState(["All", "Yes", "No"]);
  const [top, setTop] = useState("All");

  const fetchInterval = useRef<number | null>(null);
  const isFetching = useRef(false);

  const [page, setPage] = useState(1);
  const [division] = useState(15);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(1);

  const [categories, setCategories] = useState<tCategories>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [reload, setReload] = useState(true);

  const [showFilters, setShowFilters] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState<tCategory | null>(null);
  const [deleting, setDeleting] = useState<tCategory | null>(null);

  const [errorM, setErrorM] = useState("");
  const [success, setSuccess] = useState<tSuccess | null>(null);

  const deleteCategory = (category: tCategory) => {
    setDeleteConfirm(null);

    setDeleting(category);

    api_client({
      method: "DELETE",
      url: `/categories/${category._id}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        setSuccess({
          title: "Deleted!",
          message: "Category deleted successfully",
          controls: (
            <div className="success-modal__btns">
              <button
                className="button"
                onClick={() => {
                  setSuccess(null);
                }}
              >
                Close
              </button>
            </div>
          ),
          closeHandler: () => {
            setSuccess(null);
          },
        });
        setReload((rl) => !rl);
      })
      .catch((err) => {
        setErrorM(
          err.code === "ERR_BAD_REQUEST"
            ? err.response.data.message
            : err.message
        );
      })
      .finally(() => {
        setDeleting(null);
      });
  };

  const deleteHandler = (category: tCategory) => {
    if (deleteConfirm || deleting) return;

    setDeleteConfirm(category);
  };

  const toggleFilters = () => {
    setShowFilters((sf) => {
      if (sf) {
        document.querySelector("body")?.classList.remove("modal-open");
      } else {
        document.querySelector("body")?.classList.add("modal-open");
      }

      return !sf;
    });
  };

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setError(false);
    setLoading(true);

    fetchInterval.current = window.setInterval(() => {
      if (isFetching.current) return;

      isFetching.current = true;

      api_client({
        url: `/categories?page=${page}&division=${division}${
          search ? `&search=${search}` : ""
        }${top !== "All" ? `&top=${top === "Yes" ? "true" : "false"}` : ""}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const cats: tCategories = res.data.data.categories;

          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          if (!cats.length && page > 1) return setPage(page - 1);

          setCategories(cats);
        })
        .catch((err) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
          isFetching.current = false;
        });
    }, 3000);
  }, [search, top, page, division, accessToken, reload]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  const filterExists = search || top !== "All";

  return (
    <DashboardLayout>
      {showFilters ? (
        <div className="modal-overlay" onClick={() => toggleFilters()}></div>
      ) : null}

      <SuccessModal success={success} />
      <ErrorModal errorMsg={errorM} closeHandler={() => setErrorM("")} />

      {deleteConfirm ? (
        <ConfirmationModal
          confirm={{
            title: "Delete Category?",
            message: `Are you sure you want to delete category ${deleteConfirm.Name}. This action cannot be reversed`,
            closeHandler: () => setDeleteConfirm(null),
            confirmHandler: () => deleteCategory(deleteConfirm),
          }}
        />
      ) : null}

      <div className="page-header">
        <div className="page-header__left">
          <h3 className="page-header__heading">Categories</h3>
          <ul className="page-header__breadcrumb">
            <li className="page-header__breadcrumb-item">
              <Link to="#">Home</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <Link to="#">Categories</Link>
            </li>
            <li className="page-header__breadcrumb-item">
              <span>List</span>
            </li>
          </ul>
        </div>
        <div className="page-header__right">
          <Link to="/add-category" className="btn">
            <Icon name="add" />
            Add Category
          </Link>
        </div>
      </div>
      <div className={cls("tfilter", showFilters && "open")}>
        <div className="tfilter__header">
          <h3 className="tfilter__heading">Table Filters</h3>
          <span className="tfilter__close" onClick={() => toggleFilters()}>
            <IonIcon icon={closeOutline} />
          </span>
        </div>
        <div className="tfilter__body">
          <div className="fgroup">
            <label className="label">Top Category?</label>
            <SimpleSelect
              type="single"
              placeholder="Select..."
              useSearch={false}
              bind={[top, setTop]}
              data={topOptions}
            />
          </div>
        </div>
        <div className="tfilter__footer" onClick={() => toggleFilters()}>
          <button className="btn">Show Results</button>
        </div>
      </div>
      <div className="table-block-1">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="search-box search-box--filled">
              <IonIcon icon={searchOutline} className="search-box__icon" />
              <input
                className="input search-box__input"
                type="text"
                placeholder="Search by anything...."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search ? (
                <span
                  className="search-box__clear"
                  onClick={(e) => setSearch("")}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
            </div>
          </div>
          <div className="table-controls__right">
            <div className="table-controls__filters">
              <button className="button" onClick={() => toggleFilters()}>
                <Icon name="filter" />
                Filters
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="table-block">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="th">ID</div>
                    </th>
                    <th>
                      <div className="th">Name</div>
                    </th>
                    <th>
                      <div className="th">Top</div>
                    </th>
                    <th>
                      <div className="th">Books</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5}>
                        <VerticalBarLoader sm />
                      </td>
                    </tr>
                  ) : null}
                  {error ? (
                    <tr>
                      <td colSpan={5}>
                        <div className="text-center">
                          Error fetching categories.{" "}
                          <span
                            className="text-link"
                            onClick={() => setReload((rl) => !rl)}
                          >
                            Try again
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && !categories.length ? (
                    <tr>
                      <td colSpan={5}>
                        <div className="text-center">
                          There are no categories{" "}
                          {filterExists
                            ? "in current filter"
                            : "currently on the platform"}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {!loading && !error && categories.length
                    ? categories.map((category) => (
                        <tr key={category._id}>
                          <td>#{category._id.slice(0, 6)}</td>
                          <td>
                            <div className="titem">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: htmr(category.Icon) as string,
                                }}
                              ></div>{" "}
                              {category.Name}
                            </div>
                          </td>
                          <td>
                            <div className="titem">
                              {category.Top ? "YES" : "NO"}
                            </div>
                          </td>
                          <td>
                            <div className="titem">
                              {category.NumBooks} books
                            </div>
                          </td>
                          <td>
                            <div className="tactions f-justify-start">
                              <button
                                className="taction"
                                onClick={() =>
                                  navigate(`/category/${category._id}/edit`)
                                }
                              >
                                <IonIcon icon={createOutline} /> Edit
                              </button>
                              {!category.NumBooks ? (
                                <button
                                  className="taction"
                                  onClick={() => deleteHandler(category)}
                                >
                                  {deleting?._id === category._id ? (
                                    <>
                                      <span className="fas fa-spinner fa-spin"></span>{" "}
                                      Deleting
                                    </>
                                  ) : (
                                    <>
                                      <IonIcon icon={trashOutline} />
                                      Delete
                                    </>
                                  )}
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="table-footer">
          <div className="division">
            {!loading && !error && numRecords
              ? `Showing ${start} - ${end} of ${numRecords} results`
              : null}
          </div>
          <div className="pagination">
            <button onClick={() => (page > 1 ? setPage(page - 1) : null)}>
              <IonIcon icon={chevronBackOutline} />
            </button>
            {new Array(pagination).fill(null).map((_, i) => (
              <button className={cls(page - 1 === i && "active")} key={i}>
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => (page < pagination ? setPage(page + 1) : null)}
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default withAuth(Categories);
