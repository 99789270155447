import { MONTHS, MONTHS_SHORT } from "../data";

export const getSkip = (page: number, division: number) =>
  (page - 1) * division;

export const getDateTime = (date_string: string, short: boolean = false) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${
    short ? MONTHS_SHORT[month] : MONTHS[month]
  } ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
    2,
    "0"
  )} ${meridian}`;

  return [comp_date, comp_time];
};

export const roundDP = (
  value: number | string,
  len: number,
  noCommas?: boolean
) => {
  // value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return noCommas ? `${bfDec}.${s}` : `${Number(bfDec).toLocaleString()}.${s}`;
};

export const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "kB", "mB", "gB", "tB", "pB", "eB", "zB", "yB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getInputDate = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth() + 1;
  const year = new_date.getFullYear();

  const comp_date = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  return `${comp_date}`;
};
